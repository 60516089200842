@import '~antd/dist/antd.css';

/* RWD Container */
.container {
    width: 100%;
    display: flex;
    align-self: center;
    background-color: #fff;
}

/* sm */
/* @media (min-width: 414px) { 
  .container {
    width: 414px;
  }
}  */

/* md */
@media (max-width: 768px) {
  .container {
    width: 768px;
  }
} 

/* lg */
@media (max-width: 1200px) {
  .container {
    width: 1200px;
  }
} 

/* xl */
@media (max-width: 1500px) {
  .container {
    width: 1500px;
  }
} 

/* xxl */
@media (min-width: 1920px) {
  .container {
    width: 1920px;
  }
} 

/* general */
body {
  font-size: 16px;
  line-height: 1.6;
  background-color: #f1f1f1;
  color: #000;
}
.layout-content {
  background-color: #f1f1f1;
}
.main-layout {
  display: flex;
  flex-direction: row;
}
.bg-gray {
  background-color: #f1f1f1;
}

/*Login*/
.ad_1{
  width: 100%;
}
.logo{
  width: 100%;
  margin-left: 16rem;
  margin-top: 10rem;
}
.login-form-wrapper{
  width: 48.5%;
  background-color: #004ea8;
}
.login-form{
  width: 51.5%;
  background-color: #004ea8;
  justify-content: center;
  align-items: center;
}
.login-form_list{
  justify-content: center;
  align-items: center;
  max-width: 25vw;
}
.login-email{
  width: 25vw;
  margin: 3rem 14.5vw 1rem;
}
.login-password{
  width: 25vw;
  margin: 2.5rem 14.5vw 1rem;
}
.login-enter{
  width: 25vw;
  margin: 0rem 13vw 1.5rem;
  text-align: center;
}
.remember{
  width: 25vw;
  margin-left: 22.5vw;
  color: #fff;
  font-size: 18px;
}
.login-form__button{
  width: 216px;
  height: 48px;
  margin-top: 1rem;
  background-color: #00d1ff;
  border-radius: 3px;
  border-color: #00d1ff;
  color: #333333;
  font-size: 18px;
}
.login-form__button:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #ffffff;
}
.login-form__button:focus{
  background-color: #003777;
  border-color: #003777;
  color: #ffffff;
}
.login-form__forgot{
  width: 440px;
  color: #00d1ff;
  text-decoration: underline;
  font-size: 18px;
  margin-left: 22vw;
}
.login_info{
  color: #fff;
  font-size: 18px;
  margin-left: 15vw;
  width: 400px;
  text-align: center;
  line-height: 40px;
}

.login-form__error-title, .login-form__error-message{
  color:'#fff500';
  width: 400px;
  margin-left: 2rem;
  font-size: 16px;
}

/*add customer card*/
.addcustomer-wrapper{
  width: 48.5%;
}
.addcustomer-form{
  width: 51.5%;
  justify-content: center;
  align-items: center;
}
.addcustomer{
  width: 100%;
  text-align: center;
  margin-top: 120px;
}
.addcustomer-title{
  font-size: 36px;
  line-height: 1.33rem;
}
.hr-addcustomerline{
  width: 50%;
  height: 1px;
  border: 0;
  border-top: solid 2px;
  border-color: #000;
}
.accountform_list{
  max-width: 25vw;
}
.addcustomer_form{
  width: 25vw;
  margin: 0rem 13vw 1rem;
}
.addcustomer_btn{
  width: 225px;
  height: 50px;
  margin-top: 1rem;
  background-color: #004ea8;
  border-radius: 3px;
  border-color: #00d1ff;
  color: #fff;
  font-size: 18px;
}
.addcustomer_btn:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #ffffff;
}
.addcustomer_btn:focus{
  background-color: #003777;
  border-color: #003777;
  color: #ffffff;
}

/*customer check*/
.back_btn{
  margin-left: 780px;
  margin-top: -1150px;
}
.nexmowlogo{
  width: 511px;
  margin-top: 7rem;
  margin-left: 15rem;
}
.customerckeck-title{
  width: 620px;
  font-size: 48px;
  margin-left: 12rem;
}
.customerckeck{
  margin-top: 130px;
  text-align: left;
  margin-left: 15rem;
}
.ckeckform-title{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.ckeckform-detail{
  font-size: 24px;
  line-height: 15px;
}
.customersent_btn{
  width: 250px;
  height: 48px;
  margin-top: 7rem;
  background-color: #004ea8;
  border-radius: 3px;
  border-color: #00d1ff;
  color: #fff;
  font-size: 18px;
  margin-left: 23rem;
}
.customersent_btn:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #ffffff;
}
.customersent_btn:focus{
  background-color: #003777;
  border-color: #003777;
  color: #ffffff;
}
/* @media (max-width: 768px) {
  .collapse-mobile {
    transform: translateX(-100%);
    justify-content: center;
    align-items: center;
  }

  .nav-bar {
    background-color: #004ea8;
    position: absolute;
    z-index: 99;
    width: 80%;
    height: 100%;
    flex-wrap: wrap;
    position: sticky;
    left: 0;
  }

  .nav-item {
    font-size: 1.2em;
    margin: 2rem auto;
    width: 50%;
  }

  .collapse-mobile-wrap {
    display: none;
  }

  .nav-bar-wrap {
    background-color: #000;
    opacity: 0.6;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
  }
} */

.hr-header-line {
  margin: 25px auto 30px;
  padding: 0;
  width: 100px;
  border: 0;
  border-top: solid 6px;
  border-color: #6366F2;
  border-radius: 50%;
  opacity: 1;
}

/* Hamburger Menu */
.ham-menu {
  display: inline-block;
  position: relative;
  margin-left: -4rem;
  z-index: 999;
  background-color: #111828;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2rem;
  opacity: 0.7;
  left: 5rem;
  top: 2.5rem;
  transition: all .25s linear;
}

@media (min-width: 768px) {
  .show-mobile {
    display: none;
  }
}

.ham-menu:hover {
  opacity: 1;
  cursor: pointer;
}
span.ham-menu-bar1 {
  display: inline-block;
  position: absolute;
  background-color: #8183ff;
  width: 24px;
  height: 3px;
  top: 0rem;
  transition: all .05s linear;
  transform: rotate(-4deg);
}
span.ham-menu-bar2 {
  display: inline-block;
  background-color: #8183ff;
  position: absolute;
  width: 24px;
  height: 3px;
  top: 0.55rem;
  transition: all .25s linear;
  transform: rotate(2deg);
}
span.ham-menu-bar3 {
  display: inline-block;
  background-color: #8183ff;
  position: absolute;
  width: 24px;
  height: 3px;
  top: 1.1rem;
  transition: all .25s linear;
  transform: rotate(-2deg);
}
span.ham-menu-bar1--touch {
  display: inline-block;
  position: absolute;
  background-color: #8183ff;
  width: 30px;
  height: 3px;
  top: 16px;
  opacity: 0;
  transition: all .05s linear;
}
span.ham-menu-bar2--touch {
  display: inline-block;
  background-color: #8183ff;
  position: absolute;
  width: 30px;
  height: 3px;
  opacity: 1;
  top: 0.6rem;
  transition: all .25s linear;
  transform: rotate(45deg);
}
span.ham-menu-bar3--touch {
  display: inline-block;
  background-color: #8183ff;
  position: absolute;
  width: 30px;
  height: 3px;
  top: 0.6rem;
  opacity: 1;
  transition: all .25s linear;
  transform: rotate(-45deg);
}
.account{
  text-align: center;
}
.accountpic{
  width: 90px;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.account_name{
  font-size: 24px;
  color: #fff;
  margin-top: 2rem;
}
.nav-bar {
  background-color: #004ea8;
  display: flex;
  width: 302px;
  flex-direction: column;
  padding-top: 2rem;
  min-height: 900px;
  transition: all 0.25s linear;
}
.nav-item {
  font-size: 24px;
  margin-top: 1rem;
  cursor: pointer;
  color: #00d1ff;
  transition: opacity .25s linear;
  padding: 1rem 0rem 0rem 2rem;
}
.nav-item:hover{
  color: #000;
  opacity: 1;
  background-color: #fff500;
  height: 75px;
}
.nav-item--active {
  opacity: 1;
  background-color: #ffca43;
  height: 75px;
  color: #000;
}
.ant-drawer-header {
  border-bottom: none;
}
.ant-drawer-title {
  text-align: center;
}
.logout_btn{
  width: 225px;
  height: 50px;
  border-radius: 50px;
  border: solid 3px #fff;
  text-align: center;
  margin-top: 450px;
  margin-left: 40px;
  background-color: #004ea8;
  color: #fff;
  font-size: 18px;
}
.logout_btn:hover{
  background-color: #fff500;
  border-color: #fff;
  color: #ffffff;
}
.logout_btn:focus{
  background-color: #ff8a00;
  border-color: #fff;
  color: #ffffff;
}

.layout_limit{
  width: 100%;
  margin-top: 7rem
} /*Anita 0530*/

/*customer mag*/
.mainform-wrapper{
  justify-content:space-around;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}
.main-title{
  font-size: 36px;
  margin-bottom: 0;
  
}
.addcustomer__button{
  width: 260px;
  height: 48px;
  background-color: #004ea8;
  border-radius: 6px;
  border-color: #004ea8;
  color: #fff;
  font-size: 18px;
}
.addcustomer__button:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #ffffff;
}
.addcustomer__button:focus{
  background-color: #003777;
  border-color: #003777;
  color: #ffffff;
}
.hr-line{
  width: 80%;
  height: 1px;
  border: 0;
  border-top: solid 2px;
  border-color: #000;
}
.set-wrapper{
  justify-content:space-evenly;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.searchbar{
  width: 45vw;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover{
  background-color: #fff;
  border-color: #000;
  border-radius: 3px;
  /* width: 45vw; */
}
.ant-input{
  background-color: #fff;
  font-size: 18px;
}
.search_icon{
  width: 30px;
  margin: 0.3rem 0rem 0rem 0.3rem;
}
.de_search_icon{
  margin-left: -10rem;
}

/*robot mag*/
.robottrans-wrapper{
  justify-content:space-evenly;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}
.back_s_btn{
  margin-left: 7rem;
  margin-top: -7rem;
}
/*robot l3l4*/
.robotform-wrapper{
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-left: 12rem;
}
/*account mag*/
.accform-wrapper{
  justify-content:space-around;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}
.acc-title{
  font-size: 36px;
  margin-bottom: 0;
  width: 360px;
}
.acc_payment__button{
  width: 260px;
  height: 48px;
  background-color: #ffca43;
  border-radius: 6px;
  border-color: #ffca43;
  color: #000;
  font-size: 20px;
}
.acc_payment__button:hover{
  background-color: #fff500;
  border-color: #fff500;
  color: #000;
}
.acc_payment__button:focus{
  background-color: #ff8a00;
  border-color: #ff8a00;
  color: #ffffff;
}
.acc_btn{
  margin-left: -20rem;
}
.space_btn{
  margin-left: -15rem;
}

/*customer robot account list*/
.list-wrapper{
  /* display: flex; */
  flex-direction: row;
  margin-left: 9rem;
} /*Anita 0530*/

.list_item{
  width: 90%;
  border: solid 1px #000;
  border-radius: 3px;
  border-color: #000;
  color: #000;
  justify-content:space-around;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.custom_address, .account_address,  .robot_date{
  font-size: 18px;
  margin-top: 1rem;
  width: 300px
} /*Anita 0530*/

.account_email,.custom_email{
  font-size: 18px;
  margin-top: 1rem;
  width: 200px;
}
.custom_company, .custom_name, .custom_phone, .custom_robot, .custom_job, 
.robot_psn, .robot_company, .robot_name, 
.account_company, .acc_name, .account_job, .account_phone{
  font-size: 18px;
  margin-top: 1rem;
  width: 160px; /*Anita 0530*/
}

.robot_signal{
  margin-top: 0.8rem;
}

.robot_date_b{
  margin-left: -25rem;
}

/*robot transfer*/
.robot_select{
  margin-top: 1.5rem;
  width: 25px;
  height: 25px;
} /*Anita 0530*/
  
.robot_rent_button{
  width: 220px;
  height: 48px;
  background-color: #ffca43;
  border-radius: 6px;
  border-color: #ffca43;
  color: #000;
  font-size: 18px;
}
.robot_rent_button:hover{
  background-color: #fff500;
  border-color: #fff500;
  color: #000;
}
.robot_rent_button:focus{
  background-color: #ff8a00;
  border-color: #ff8a00;
  color: #ffffff;
}
/*robot transfer PSN*/
.psnfinish__button{
  width: 220px;
  height: 48px;
  background-color: #c4c4c4;
  border-radius: 6px;
  border-color: #c4c4c4;
  color: #fff;
  font-size: 18px;
}
.psnfinish__button:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #ffffff;
}
.psnfinish__button:focus{
  background-color: #003777;
  border-color: #003777;
  color: #ffffff;
}
.robotnum_wrapper{
  justify-content:space-around;
  display: flex;
  flex-direction: row;
}
.robottrans_notice{
  color:#ff0000;
  font-size: 20px;
  margin-top: 1rem;
}
.drawer_word{
  font-size: 24px;
  margin-left: 12rem;
}
.robot_sn_psn{
  width: 210px;
  font-size: 18px;
  margin-top: 1rem;
  margin-left: 3rem;
}
.robot_model{
  width: 210px;
  font-size: 18px;
  margin-top: 1rem;
  margin-left: 3rem;
}
.robot_psn{
  margin-left: 2rem;
  margin-top: 0.8rem;
}
.robot_confirm{
  font-size: 18px;
  margin-right: 0.5rem;
}
.psn_inputform{
  margin-left: 1vw;
  width: 480px;
  margin-top: 0.8rem;
}
/* robot activate*/
.activate_list{
  justify-content: space-around;
  align-items: center;
}
.datepicker{
  margin-top: -3.5rem;
  margin-left: 40vw;
}
.sn_sim{
  justify-content:space-around;
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
}
.sim_de{
  width: 350px;
  height: 35px;
  margin-top: 0.8rem;
}
.psn_de{
  width: 350px;
  height: 35px;
  margin-top: 0.8rem;
}
.robot_sn_sim{
  font-size: 18px;
  width: 200px;
  margin-top: 1rem;
}

/*customer account item*/
.user_company, .user_email, .account_company, .account_email{
  font-size: 18px;
}

.account_remove__button{
  width: 220px;
  height: 48px;
  background-color: #e40000;
  border-radius: 6px;
  border-color: #e40000;
  color: #fff;
  font-size: 18px;
}
.account_remove__button:hover{
  background-color: #ff3333;
  border-color: #ff3333;
  color: #fff;
}
.account_remove__button:focus{
  background-color: #bf0000;
  border-color: #bf0000;
  color: #ffffff;
}
.account_detail_form{
  width: 500px;
  margin: 0rem 7vw 1rem;
}

/*update btn style*/
.update_btn{
  width: 220px;
  height: 48px;
  background-color: #004ea8;
  border-radius: 6px;
  border-color: #004ea8;
  color: #fff;
  font-size: 18px;
}
.update_btn:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #ffffff;
}
.update_btn:focus{
  background-color: #003777;
  border-color: #003777;
  color: #ffffff;
}

/*modal btn style*/
.modal_btn{
  width: 220px;
  height: 48px;
  border-radius: 6px;
  border: solid 2px #000;
  color: #000;
  font-size: 18px;
}
.modal_btn:hover{
  border-radius: 6px;
  border: solid 2px #000;
  color: #000;
}
.modal_btn:focus{
  border-radius: 6px;
  border: solid 2px #000;
  color: #000;
}

/*auto test*/
.select_title{
  width: 470px;
  height: 76px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}
.select_box{
  margin: 1rem;
  width: 25px;
  height: 25px;
} 
.box{
  margin-top: 1.5rem;
}
.select_desc{
  font-size: 20px;
  color: #181818;
  margin: 1.2rem;
} 
.dis{
  color: #dadada;
} 
.run_select_button{
  width: 200px;
  height: 40px;
  margin: 15px 0 0 30px;
  background-color: #e40000;
  border-radius: 50px;
  border-color: #e40000;
  color: #fff;
  font-size: 18px;
}
.run_select_button:hover{
  background-color: #ff3333;
  border-color: #ff3333;
  color: #fff;
}
.run_select_button:focus{
  background-color: #bf0000;
  border-color: #bf0000;
  color: #fff;
}
.select_detail{
  width: 470px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.div{
  margin-left: 2.3rem;
}
.select_bar{
  width: 470px;
  height: 1080px;
  overflow-y: auto;
}
.select_item{
  width: 470px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}
.select_item_desc{
  width: 380px;
  font-size: 20px;
  color: #181818;
  margin: 18px 0 0 10px;
} 
.select_robotdetail{
  width: 337px;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.select_robot_title{
  font-weight: bold;
  font-size: 20px;
  color: #181818;
  margin-top: 1.5rem;
}
.select_robot_num{
  font-weight: bold;
  font-size: 16px;
  color: #181818;
  margin-top: 1.5rem;
}
.serial_num{
  width: 13vw;
}
.select_robot_desc{
  font-weight: bold;
  font-size: 16px;
  color: #c0c0c0;
  margin-top: 1.1rem;
}
.tit{
  margin-top: 0.5rem;
}
.robot_connect_btn{
  width: 225px;
  height: 50px;
  background-color: #ffca43;
  border-radius: 10px;
  border-color: #ffca43;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-left: 1.3rem;
  margin-top: 1.1rem;
}
.robot_connect_btn:hover{
  background-color: #fff500;
  border-color: #fff500;
  color: #000;
  font-weight: bold;
}
.robot_connect_btn:focus{
  background-color: #ff8a00;
  border-color: #ff8a00;
  color: #000;
  font-weight: bold;
}
.robot_connect_btn:focus{
  background-color: #ff8a00;
  border-color: #ff8a00;
  color: #000;
  font-weight: bold;
}
.ant-progress-steps-item{
  width: 24px;
  height: 12px;
}
.robot_notice_card{
  width: 775px;
  height: 1040px;
  margin: 20px 15px 14px;
  padding: 35px 94px 149px;
  background-color: #fff;
}
.robot_notice_desc{
  font-weight: bold;
  font-size: 20px;
  color: #181818;
  margin-top: 15px;
  text-align: center;
}
.robot_notice_pic{
  margin-top: 2rem;
}
.robot_disconnect_btn{
  width: 225px;
  height: 50px;
  background-color: #004ea8;
  border-radius: 10px;
  border-color: #004ea8;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-left: 1.3rem;
  margin-top: 3.5rem;
}
.robot_disconnect_btn:hover{
  background-color: #0469ff;
  border-color: #0469ff;
  color: #fff;
  font-weight: bold;
}
.robot_disconnect_btn:focus{
  background-color: #003777;
  border-color: #003777;
  color: #fff;
  font-weight: bold;
}
.robot_export_btn{
  width: 225px;
  height: 50px;
  background-color: #dadada;
  border-radius: 10px;
  border-color: #dadada;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-left: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.hashloader{
  margin-left: 23rem;
  margin-top: 28rem;
}
.hashloader_word{
  margin-left: 21rem;
  margin-top: 3rem;
  font-size: 20px;
}
.testcard{
  width: 800px;
  height: 1080px;
}
.select_testcard{
  width: 372px;
  height: 245px;
  background-color: #fff;
  margin: 20px 0px 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.test_title{
  font-size: 18px;
  font-weight: bold;
  color:#181818;
  margin-top: 10px;
}
.test_switch{
  margin-top: 15px;
  margin-left: 1rem;
}
.test_desc{
  font-weight:normal;
  width: 300px;
  height: 57px;
  text-align: center;
}
.image_test{
  margin-top: -1rem;
}
.progress{
  top: 1rem;
}
.test_page_layout{
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.test_button{
  width: 120px;
  height: 40px;
  margin: 0px 30px 0 40px;
  background-color: #00d1ff;
  border-radius: 50px;
  border-color: #00d1ff;
  color: #fff;
  font-size: 18px;
}
.test_button:hover, .test_button:focus{
  background-color: #00d1ff;
  border-color: #00d1ff;
  color: #fff;
}
.test_button_stop{
  width: 120px;
  height: 40px;
  margin: 0px 30px 0 45px;
  background-color: #e40000;
  border-radius: 50px;
  border-color: #e40000;
  color: #fff;
  font-size: 18px;
}
.test_button_stop:focus, .test_button_stop:hover{
  background-color: #e40000;
  border-color: #e40000;
  color: #fff;
}